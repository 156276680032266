<template>
  <div>
    <ContractDeclineReasonSelect v-model="formValues.reason" required />

    <BaseTextArea
      label="SEMINAR_MANAGEMENT.SEMINAR_DOCUMENTS.CONTRACT_MANAGEMENT.DECLINE_FORM.LABEL.COMMENT"
      required
      v-model="formValues.comment"
      v-if="showCommentField"
    />
  </div>
</template>

<script>
import ContractDeclineReasonSelect from "@components/FormElements/ContractDeclineReasonSelect.vue";

export default {
  name: "DeclineForm",

  components: {
    ContractDeclineReasonSelect,
  },

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        comment: null,
        reason: null,
      }),
    },
  },

  computed: {
    showCommentField() {
      if (!this.formValues.reason) return false;

      return this.formValues.reason.value.needsComment;
    },

    formValues: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
