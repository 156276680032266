<template>
  <div class="mb-10">
    <SeminarDocuments :documents="seminarDocuments" v-if="hasSeminarDocuments" class="mb-8" />
    <SeminarDocuments
      :documents="seminarContracts"
      v-if="hasSeminarContracts"
      title="SEMINAR_MANAGEMENT.SEMINAR.SHOW.DOCUMENTS.CONTRACTS"
    />
  </div>
</template>

<script>
import documentTypes from "@data/documentTypes";
import SeminarDocuments from "@components/AuthSeminar/SeminarDocuments";
import { isEmpty } from "lodash";

export default {
  name: "Documents",

  components: {
    SeminarDocuments,
  },

  data() {
    return {
      documents: [],
      isLoading: true,
    };
  },

  props: {
    seminar: {
      type: Object,
      required: true,
    },
  },

  computed: {
    seminarContracts() {
      return this.documents.filter(
        ({ documentType }) => documentType.display_name === documentTypes.CONTRACTS
      );
    },

    hasSeminarContracts() {
      return this.seminarContracts.length > 0;
    },

    seminarDocuments() {
      return this.documents.filter(
        ({ documentType }) => documentType.display_name !== documentTypes.CONTRACTS
      );
    },

    hasSeminarDocuments() {
      return this.seminarDocuments.length > 0;
    },
  },

  methods: {
    async listDocuments() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.seminarManagement.listSeminarDocuments({
          page: 1,
          perPage: 1000,
          seminarId: this.seminar.id,
        });

        this.documents = data.data;

        this.$nextTick(() => {
          this.emitFirstDocumentId(data.data);
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    emitFirstDocumentId(documents) {
      if (isEmpty(documents)) return;

      this.$emit("loadDocuments", this.seminarContracts[0].id);
    },
  },

  created() {
    this.listDocuments();
  },
};
</script>
