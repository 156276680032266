<template>
  <v-row no-gutters align="center">
    <v-col cols="auto" v-if="showAcceptAction">
      <ConfirmationDialog
        v-model="showAcceptDialog"
        title="SEMINAR_MANAGEMENT.SEMINAR_DOCUMENTS.CONTRACT_MANAGEMENT.ACCEPT_DIALOG.TITLE"
        @confirm="acceptContract"
        labelConfirm="SEMINAR_MANAGEMENT.SEMINAR_DOCUMENTS.CONTRACT_MANAGEMENT.ACCEPT_DIALOG.LABEL_CONFIRM"
        :loading="isLoading"
        body="SEMINAR_MANAGEMENT.SEMINAR_DOCUMENTS.CONTRACT_MANAGEMENT.ACCEPT_DIALOG.BODY"
        color="primary"
        label="SEMINAR_MANAGEMENT.SEMINAR_DOCUMENTS.CONTRACT_MANAGEMENT.ACCEPT_DIALOG.LABEL_BTN"
        small
      />
    </v-col>

    <v-col cols="auto" v-if="showDeclineAction" class="ml-4">
      <ConfirmationDialog
        v-model="showDeclineDialog"
        title="SEMINAR_MANAGEMENT.SEMINAR_DOCUMENTS.CONTRACT_MANAGEMENT.DECLINE_DIALOG.TITLE"
        @confirm="cancelContract"
        labelConfirm="SEMINAR_MANAGEMENT.SEMINAR_DOCUMENTS.CONTRACT_MANAGEMENT.DECLINE_DIALOG.LABEL_CONFIRM"
        :loading="isLoading"
        color="error"
        trigger-color="error"
        label="SEMINAR_MANAGEMENT.SEMINAR_DOCUMENTS.CONTRACT_MANAGEMENT.DECLINE_DIALOG.LABEL_BTN"
        small
      >
        <v-form @submit.prevent v-model="formValidation" lazy-validation ref="form">
          <DeclineForm v-model="formValues" />
        </v-form>
      </ConfirmationDialog>
    </v-col>
  </v-row>
</template>

<script>
import contractStatusTypes from "@data/contractStatusTypes";
import countDownHelper from "@helper/countDownHelper";
import ConfirmationDialog from "@components/ConfirmationDialog/index.vue";
import contractStatusActions from "@data/contractStatusActions";
import DeclineForm from "./DeclineForm.vue";
import formValidation from "@mixins/formValidation";
import { isEmpty } from "lodash";
import { CONTRACT_CANCELLATION_DEADLINE } from "@data/contractCancellationDeadline";

export default {
  name: "ContractAction",

  data() {
    return {
      isLoading: false,
      showAcceptDialog: false,
      showDeclineDialog: false,
      isValidating: false,
      formValues: {
        reason: null,
        comment: null,
      },
    };
  },

  mixins: [formValidation],

  components: {
    DeclineForm,
    ConfirmationDialog,
  },

  props: {
    contractStatus: {
      type: Object,
      required: true,
    },

    seminarStartAt: {
      type: String,
      required: true,
    },

    contractId: {
      type: Number,
      required: true,
    },

    documentId: {
      type: Number,
      required: true,
    },

    contractActions: {
      type: Array,
      required: true,
    },
  },

  computed: {
    declineDeadlineWasReached() {
      return countDownHelper(this.seminarStartAt) <= CONTRACT_CANCELLATION_DEADLINE;
    },

    hasActions() {
      return !isEmpty(this.contractActions);
    },

    hasCancelActions() {
      return (
        this.contractActions.filter(({ action }) => action === contractStatusActions.CANCEL)
          .length > 0
      );
    },

    seminarWasDispatched() {
      return this.contractStatus.erp_key === contractStatusTypes.DISPATCHED;
    },

    showAcceptAction() {
      return this.seminarWasDispatched && !this.hasActions;
    },

    showDeclineAction() {
      return !this.declineDeadlineWasReached && !this.hasCancelActions && this.seminarWasDispatched;
    },

    payloadAccept() {
      return {
        speakerContractId: this.contractId,
        action: contractStatusActions.CONFIRM,
        reason: null,
        comment: null,
        documentId: this.documentId,
      };
    },

    payloadCancel() {
      return {
        speakerContractId: this.contractId,
        action: contractStatusActions.CANCEL,
        reason: this.formValues.reason?.value.name,
        comment: this.formValues.comment,
        documentId: this.documentId,
      };
    },
  },

  methods: {
    async addAction(payload) {
      this.isLoading = true;

      try {
        await this.$axios.seminarManagement.addContractAction(payload);
        this.$emit("reload");
      } finally {
        this.isLoading = false;
      }
    },

    async acceptContract() {
      await this.addAction(this.payloadAccept);
      this.showAcceptDialog = false;
    },

    async cancelContract() {
      this.validate();

      this.$nextTick(async () => {
        if (!this.formValidation) return;

        this.isValidating = true;
        try {
          await this.addAction(this.payloadCancel);
          this.showDeclineDialog = false;
        } finally {
          this.isValidating = false;
        }
      });
    },
  },
};
</script>
