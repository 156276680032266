<template>
  <ModuleSubPage fullWidth :loading="isLoading" v-if="seminar">
    <BaseHasPermission :permissionProps="['isSpeaker']">
      <v-row>
        <v-col cols="12">
          <BaseBackButton :route="{ name: 'seminar-speaker-my-contracts' }" />
        </v-col>

        <v-col cols="8">
          <BaseHeadline size="2" class="mb-6">
            {{ seminar.title }}
          </BaseHeadline>

          <SeminarDescription
            :seminarDescription="seminar.description"
            class="mb-6"
            v-if="seminar.description"
          />

          <SpeakerContracts :seminar="seminar" :documentId="documentId" />

          <Documents :seminar="seminar" @loadDocuments="setDocumentId($event)" />
        </v-col>

        <v-col cols="4">
          <SeminarInfoBar :seminar="seminar" />
        </v-col>
      </v-row>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import SeminarDescription from "@components/AuthSeminar/SeminarDescription.vue";
import SeminarInfoBar from "@views/Restricted/SeminarSpeaker/MyContracts/Show/partials/SeminarInfoBar/index.vue";
import Documents from "@views/Restricted/SeminarSpeaker/MyContracts/Show/partials/Documents";
import SpeakerContracts from "./partials/SpeakerContracts/index.vue";

export default {
  name: "SeminarSpeakerMyContractsShow",

  components: {
    SpeakerContracts,
    ModuleSubPage,
    SeminarDescription,
    SeminarInfoBar,
    Documents,
  },

  data() {
    return {
      isLoading: true,
      seminar: null,
      contracts: [],
      contractActions: null,
      documentId: 0,
    };
  },

  computed: {
    seminarId() {
      return this.$route.params.seminarId;
    },
  },

  methods: {
    async showSeminar() {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.seminarManagement.showSeminarSpeakerSeminar({
          seminarId: this.seminarId,
        });
        this.seminar = data.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    setDocumentId(event) {
      this.documentId = event;
    },
  },

  async created() {
    await this.showSeminar();
  },
};
</script>
