<template>
  <BaseLabel :color="color.background">
    <BaseDot :color="color.text" />
    <span class="text-caption font-weight-bold" :class="`${color.text}--text`">
      {{ contractStatus.display_name }}
    </span>
  </BaseLabel>
</template>

<script>
import contractTypes from "@data/contractStatusTypes";

export default {
  name: "ContractStatus",

  props: {
    contractStatus: {
      type: Object,
      required: true,
    },
  },

  computed: {
    color() {
      switch (this.contractStatus.erp_key) {
        case contractTypes.HEARING:
          return {
            text: "warningText",
            background: "warningBackground",
          };
        case contractTypes.CANCELED:
          return {
            text: "critical",
            background: "criticalBackground",
          };
        case contractTypes.ACCEPTED:
          return {
            text: "success",
            background: "successBackground",
          };
        default:
          return {
            text: "primary",
            background: "primary200",
          };
      }
    },
  },
};
</script>
