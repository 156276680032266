/**
 *
 * Calculate remaining days until today
 *
 * @param {Date | string} date
 * @returns {number}
 * @author Dominik Niemann <dominik@polargold.de>
 * @example countDownHelper(new Date('2022-03-03'))
 */
const countDownHelper = (date) => {
  const today = new Date().getTime();
  const difference = new Date(date).getTime() - today;
  const remaining = parseInt(difference, 10) / 86400000;
  const remainingDays = Math.ceil(remaining);

  return !remainingDays ? null : remainingDays;
};

export default countDownHelper;
