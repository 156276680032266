<template>
  <BaseSelect
    :items="options"
    v-model="selectValue"
    v-bind="$attrs"
    v-on="$listeners"
    return-object
    label="SEMINAR_MANAGEMENT.SEMINAR_DOCUMENTS.CONTRACT_MANAGEMENT.DECLINE_FORM.LABEL.REASON"
  />
</template>

<script>
export default {
  name: "LevelIdSelect",

  data() {
    return {
      options: [],
    };
  },

  props: {
    value: {
      type: undefined,
      required: true,
    },
  },

  computed: {
    selectValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    async listReasons() {
      const { data } = await this.$axios.seminarManagement.listContractDeclineReasons({
        page: 1,
        perPage: 1000,
      });

      this.options = data.data.map(({ displayName, name, needsComment }) => ({
        text: displayName,
        value: {
          needsComment,
          name,
        },
      }));
    },
  },

  created() {
    this.listReasons();
  },
};
</script>
